import Responsive from 'react-responsive';
const Desktop = props => <Responsive {...props} minWidth={992}/>;
const Tablet = props => <Responsive {...props} minWidth={769} maxWidth={991}/>;
const TabletAndDesktop = props => <Responsive {...props} minWidth={769}/>;
const Mobile = props => <Responsive {...props} maxWidth={768}/>;
const MobileAndTablet = props => <Responsive {...props} maxWidth={991}/>;

export {
  Desktop,
  Tablet,
  TabletAndDesktop,
  Mobile,
  MobileAndTablet
}